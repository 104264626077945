<template>
    <v-tabs vertical>
      <v-tab
        v-for="(title, index) of titles"
        :key="index"
        class="popup-messages-tabs"
      >
        <span v-if="title.indexOf('Out') === -1">
          <v-icon v-if="title.indexOf('Polygon') === -1" left> mdi-map-marker </v-icon>
          <v-icon v-else left> mdi-vector-polygon </v-icon>
        </span>
        <span v-else>
          <v-icon  left> mdi-map-marker-remove-variant </v-icon>
        </span>
        {{ title }}
      </v-tab>

      <v-tab-item v-for="(title, num) of titles" :key="num" class="ml-12">
        <p>
          <small>Titles and texts showing to customer when he checks address</small>
        </p>
        <v-card flat class="transparent mt-12 mx-auto">
          <v-text-field
            :value="localMessages[keys[num]].title"
            @input="updateTitles[num]"
            outlined
            dense
            label="Title"
            :class="showSubscription ? 'pineapple-message-title' : 'dgtek-message-title'"
          />
          <v-textarea
            :value="localMessages[keys[num]].text"
            @input="updateTexts[num]"
            outlined
            label="Text"
          />
          <v-checkbox
            v-if="showSubscription"
            label="Subscription available"
            :input-value="localMessages[keys[num]].subscription"
            @change="updateSubscriptions[num]"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
</template>

<script>

export default {
  name: 'EditPopupMessages',

  props: ['messages'],

  data: () => ({
    titles: [],
    settings: null,
    ready: false,
    keys: [
      'on-net',
      'db-footprint',
      'db-build-commenced',
      'db-coming-soon',
      'db-not-available',
      'polygon-service-available',
      'polygon-build-commenced',
      'polygon-coming-soon',
      'out-of-footprint'
    ],
    polygons: ['Service available', 'Build commenced', 'Coming soon'],
    showSubscription: false,
    updateTitles: [],
    updateTexts: [],
    updateSubscriptions: []
  }),

  computed: {
    localMessages: {
      get () {
        return this.messages
      },
      set (data) {
        this.$emit('update:messages', data)
      }
    }
  },

  methods: {
    findSynonym (synonyms, key) {
      return synonyms.filter(synonym => synonym === key).length > 0
    },

    findTitleByKey (key) {
      if (key === 'out-of-footprint') return 'Out of footprint (Not available)'
      if (key.indexOf('polygon') !== -1) {
        const index = ['polygon-service-available', 'polygon-build-commenced', 'polygon-coming-soon']
          .indexOf(key)
        return `Polygon ${this.polygons[index]}`
      }
      const status = Object.keys(this.settings)
        .find(propName => this.findSynonym(this.settings[propName].synonyms, key))
      return status ? this.settings[status].toDisplay : 'Error: status not found in settings'
    },

    updateProp (key, propName) {
      return value => Object.assign(this.localMessages[key], { [propName]: value })
    },

    getSettings (settings) {
      this.settings = settings
      this.titles = this.keys.map(key => this.findTitleByKey(key))

      this.updateTitles = this.keys.map(key => this.updateProp(key, 'title'))
      this.updateTexts = this.keys.map(key => this.updateProp(key, 'text'))
      this.updateSubscriptions = this.keys.map(key => this.updateProp(key, 'subscription'))

      this.ready = true
    }
  },

  created () {
    this.__getEstimatedServiceDeliveryTime('all', this.getSettings)
    this.showSubscription = Object.keys(this.messages[this.keys[0]]).includes('subscription')
  }
}
</script>

<style>
.pineapple-message-title.theme--light.v-input input,
.pineapple-message-title.v-text-field input {
  color: #092!important;
  font-weight: bold;
}

.dgtek-message-title.theme--light.v-input input,
.dgtek-message-title.v-text-field input {
  color: #900!important;
  font-weight: bold;
}

.popup-messages-tabs.v-tab {
  justify-content: start !important;
  text-transform: none !important;
  border: solid 1px #e0e0e0;
}
</style>
